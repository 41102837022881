import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { config, animated, useSpring } from 'react-spring';
import Layout from '../components/layout';
import GridItem from '../components/grid-item';
import SEO from '../components/SEO';
import { ChildImageSharp } from '../types';

type PageProps = {
  data: {
    services: {
      nodes: {
        title: string;
        slug: string;
        cover: ChildImageSharp;
      }[];
    };
  };
};

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 50vw;

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr;
    grid-auto-rows: 60vw;
  }
`;

const Title = styled.h2`
  display: inline-block;
  position: absolute;
  right: 0;
  /* left: 0; */
  z-index: 10;
  margin: 0;
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 1%, rgba(0, 0, 0, 0) 100%); */
  background-color: #f00;
  /* padding: 2rem 1.5rem; */
  padding: 0.4rem 0.8rem;
  color: white;
  text-align: right;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  font-weight: 700;
  font-size: 2.074rem;
`;

const Services: React.FunctionComponent<PageProps> = ({ data: { services } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <Layout color="#fafafa">
      <SEO title="Serviços | Agência LAVA" />
      <Area style={pageAnimation}>
        {services.nodes.map((service) => (
          <GridItem
            key={`/servicos/${service.slug}`}
            to={`/servicos/${service.slug}`}
            aria-label={`View service "${service.title}"`}
          >
            <Img fluid={service.cover.childImageSharp.fluid} />
            <Title>{service.title}</Title>
          </GridItem>
        ))}
      </Area>
    </Layout>
  );
};

export default Services;

export const query = graphql`
  query Services {
    services: allServicesYaml {
      nodes {
        title
        slug
        cover {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
